import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { useRootContext } from "../RootLayout";
import { fetchMockDataPromiseWithDelay, organizations, wallets, walletTransactions } from "../services/mockData";
import {
  EstimateFee,
  Organization,
  PolicyActionDetails,
  Wallet,
  WalletTransaction,
} from "../services/openAPI/internal";
import { OrganizationService, WalletService, WalletTransactionService } from "../services/serviceLoader";
import { getFeeWalletForWithdraw, isAssetToken, shouldUseMockData } from "../utils/dataUtils";

export interface CollateralMinimal {
  assetTicker: string;
  fromWalletId: number | null;
  fromWalletAddress: string | null;
  offExchangeWalletId: number | null;
  exchange: string;
  action: string;
  quantity: string | number | null;
  originatorId: number;
  createdOn: number;
  accountId: number;
  organizationId: number;
  organizationName: string;
  clientName: string;
  requesterName: string;
  gasBalance: number;
  feeWallet: Wallet;
  isAssetToken: boolean;
}

const useGetCollateralRequest = (policyItem: PolicyActionDetails) => {
  const { assets, coldTrustFeeWallets } = useRootContext();
  const routeParams = useParams();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [collateralTransaction, setCollateralTransaction] = useState<Partial<CollateralMinimal>>(
    {} as CollateralMinimal
  );

  const [orgWallets, setOrgWallets] = useState<Wallet[]>([]);
  const [networkFeeEstimation, setNetworkFeeEstimation] = useState<EstimateFee>({});

  const orgId = Number(JSON.parse(String(policyItem.policyInstancePayload)).organizationId);

  const getCollateralTransactionDetails = async () => {
    const organizationDetails: Organization = shouldUseMockData
      ? ((await fetchMockDataPromiseWithDelay(organizations.find((o) => o.id == orgId) || [], 200)) as any)
      : ((await OrganizationService.getOrganization(orgId)) as any);

    //get all wallets by orgId
    const allOrgWallets: Wallet[] = shouldUseMockData
      ? ((await fetchMockDataPromiseWithDelay(wallets, 300)) as any)
      : ((await WalletService.getWallets(undefined, orgId)) as any);
    setOrgWallets(allOrgWallets);

    // get all transactions by orgId
    const orgTransactions: WalletTransaction[] = shouldUseMockData
      ? ((await fetchMockDataPromiseWithDelay(walletTransactions, 300)) as any)
      : ((await WalletTransactionService.getWalletTransactions(undefined, orgId)) as any);

    // find transaction item
    const walletTransactionItem = orgTransactions.find(
      (w) => w.walletTransactionId == routeParams.walletTransactionId
    ) as WalletTransaction;

    const feeEstimation = await WalletTransactionService.getEstimateFee(
      Number(walletTransactionItem.quantity),
      Number(walletTransactionItem.sourceId),
      String(walletTransactionItem.destinationAddress)
    );

    setNetworkFeeEstimation(feeEstimation as EstimateFee);

    // set CollateralTransactionsDetails
    const collateralFormData: Partial<CollateralMinimal> = {
      assetTicker: walletTransactionItem.assetTicker,
      fromWalletId: walletTransactionItem.sourceId,
      fromWalletAddress: walletTransactionItem.sourceName,
      offExchangeWalletId: walletTransactionItem.destinationId,
      exchange: "DERIBIT",
      action: walletTransactionItem.type,
      quantity: walletTransactionItem.quantity,
      createdOn: policyItem.timestamp,
      clientName: policyItem.client,
      organizationName: organizationDetails?.name,
      requesterName: policyItem.requester,
    };
    setCollateralTransaction(collateralFormData);

    const feeWallet = isAssetToken(walletTransactionItem.assetSymbol || "", assets)
      ? getFeeWalletForWithdraw(walletTransactionItem, allOrgWallets, assets, coldTrustFeeWallets)
      : undefined;
    //If there is no feeWallet set gasBalance to 0.
    const gasBalance = feeWallet ? feeWallet.availableBalance : 0;
    addERC20Values();

    function addERC20Values() {
      const isToken = isAssetToken(walletTransactionItem.assetSymbol || "", assets);
      if (isToken) {
        collateralFormData.gasBalance = gasBalance;
      }

      if (feeWallet) {
        collateralFormData.feeWallet = feeWallet;
      }

      collateralFormData.isAssetToken = isToken;
    }

    setIsLoading(false);
  };

  useEffect(() => {
    getCollateralTransactionDetails();
  }, []);

  return { isLoading, collateralTransaction, orgWallets, networkFeeEstimation };
};

export default useGetCollateralRequest;
