import { Alert, Button, Dialog, DialogTitle, Skeleton, Typography, UserInfo } from "@bakkt/bakkt-ui-components";
import { AlertTitle, Box, DialogActions, DialogContent, Unstable_Grid2 as Grid, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { useFetcher, useLocation, useNavigate, useRouteLoaderData } from "react-router-dom";

import useGetSettlementDetails, { SettlementFormData } from "../../Hooks/useGetSettlementDetails";
import { useRootContext } from "../../RootLayout";
import { SettlementConfirmationStatusEnum, WalletTransaction } from "../../services/openAPI/internal";
import { OffExchangeService } from "../../services/serviceLoader";
import { formatCurrentDate } from "../../utils/dataUtils";
import { isPolicyDisabled } from "../../utils/permissionsUtil";
import { formatActionErrorResponse, formatActionSuccessResponse } from "../../utils/responseHandlingUtils";
import ReviewSettlementLineItem, { SettlementLineItem } from "./ReviewSettlementLineItem";

interface SettlementTransactionUpdate {
  id: number;
  status: string;
}

const ReviewSettlement = () => {
  const { userInfo } = useRouteLoaderData("root") as {
    userInfo: UserInfo;
  };
  const { addAlert, setShouldRefreshPolicyItems } = useRootContext();
  const navigate = useNavigate();
  const fetcher = useFetcher();
  const { state } = useLocation();
  const theme = useTheme();
  const settlementItem = state.data;
  const isApproved = state.approved;

  const { settlementTransaction, isLoading } = useGetSettlementDetails(settlementItem);

  const [open, setOpen] = useState(true);
  const [isSubmitError, setIsSubmitError] = useState<boolean>(false);
  const [confirmedSettlementTransactions, setConfirmedSettlementTransactions] = useState<SettlementTransactionUpdate[]>(
    []
  );

  const handleClose = () => {
    setOpen(false);
    navigate(-1);
  };

  const rejectRemainingSettlements = (transactions: SettlementTransactionUpdate[]) => {
    const approvedTransactons = transactions.map((a) => a.id);
    const allSettlementTrxs = settlementTransaction?.settlementTransactions?.map((a) => a.walletTransactionId) || [];

    for (const item of allSettlementTrxs) {
      if (!approvedTransactons.find((i) => i === item)) {
        transactions.push({ id: item || 0, status: SettlementConfirmationStatusEnum.Failed });
      }
    }

    return transactions;
  };

  const handleAcknowledge = () => {
    const settlementsTrxs = rejectRemainingSettlements(confirmedSettlementTransactions);
    const acknowledgedSettlements = {
      settlementId: settlementTransaction?.settlementId,
      status: settlementsTrxs[0].status,
      transactions: settlementsTrxs,
    };

    fetcher.submit(JSON.stringify(acknowledgedSettlements), {
      method: "post",
      encType: "application/json",
    });
    navigate(`/`);
  };

  function buildSettlementLineItem(
    settlementFormData: Partial<SettlementFormData>,
    transaction: WalletTransaction
  ): SettlementLineItem {
    return {
      organizationId: settlementFormData.organizationId || 0,
      clientName: settlementFormData.clientName || "",
      orgName: settlementFormData.orgName || "",
      requesterName: settlementFormData.requesterName || "",
      createdOn: settlementFormData.createdOn || 0,
      settlementTransaction: transaction,
    };
  }

  const shouldMapOverSettlements =
    settlementTransaction && (settlementTransaction?.settlementTransactions?.length as number) > 0;

  const handleApproveSettlementlineItem = (settlementTransactionId: number, status: string) => {
    setConfirmedSettlementTransactions((prevState: SettlementTransactionUpdate[]) => {
      if (prevState.find((settlement) => settlement.id === settlementTransactionId)) {
        return prevState.filter((settlement) => settlement.id !== settlementTransactionId);
      } else {
        return [...prevState, { id: settlementTransactionId, status }];
      }
    });
  };

  useEffect(() => {
    const response = fetcher.data;
    if (response) {
      if (response.success) {
        setShouldRefreshPolicyItems(true);
        addAlert({
          severity: "success",
          messageHeader: "Settlement transactions acknowleded.",
        });
        setIsSubmitError(false);
        navigate("/");
      } else {
        setIsSubmitError(true);
      }
    }
  }, [fetcher.data]);

  return (
    <>
      {isSubmitError && (
        <Dialog open={open} onClose={handleClose} maxWidth={"md"} fullWidth={false}>
          <DialogTitle title={"Failed to submit settlement"} severity="error">
            Please try again or reach out to support for assistance.
          </DialogTitle>

          <DialogActions>
            <Button variant={"outlined"} onClick={handleClose}>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <Dialog open={open} onClose={handleClose} maxWidth={"md"} fullWidth={false}>
        <DialogTitle title={`Acknowledge Settlement`} severity="warning">
          Acknowledge organization's settlement details below.
        </DialogTitle>
        {isLoading && <Skeleton variant="rectangular" height={110} />}
        {!isLoading && !isSubmitError && (
          <>
            <Alert severity="error" sx={{ m: 4 }}>
              <AlertTitle>Settlement Transaction Confirmation</AlertTitle>
              <Box sx={{ pt: 2, mb: 2 }}>
                <Typography variant="body2" sx={{ mb: 2 }}>
                  Settlement will occur within a 2 hour window of <strong>8:30am UTC on {formatCurrentDate()}</strong>.
                  Settlement operations approvals also require device signing and must be signed with urgency.
                </Typography>
                <Typography variant="body2">
                  Additionally, please affirm within the Fireblocks Console that each of the below transactions are
                  signed and posted and confirm them by checking the boxes below to mark each transaction as
                  “Completed.”{" "}
                  <strong>
                    Once this form has been submitted, any transaction not checked will be marked within the Bakkt
                    Custody™ system as “Failed.”
                  </strong>
                </Typography>
              </Box>
            </Alert>
            <DialogContent>
              <Grid container spacing={1}>
                <Grid xs={12}>
                  <Typography sx={{ fontWeight: 600, mb: 1 }}>Confirm Settlement Transactions</Typography>
                  <Grid>
                    {shouldMapOverSettlements &&
                      settlementTransaction?.settlementTransactions?.map(
                        (transaction: WalletTransaction, index: number) => (
                          <ReviewSettlementLineItem
                            key={index}
                            settlementLineItem={buildSettlementLineItem(settlementTransaction, transaction)}
                            handleApproveSettlementlineItem={handleApproveSettlementlineItem}
                          />
                        )
                      )}
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Grid container justifyContent={"flex-end"}>
                <Button variant={"contained"} onClick={handleClose} sx={{ mr: 2 }}>
                  Cancel
                </Button>

                <Button variant={"contained"} onClick={handleAcknowledge} autoFocus>
                  Acknowledge Settlement
                </Button>
              </Grid>
            </DialogActions>
          </>
        )}
      </Dialog>
    </>
  );
};

export default ReviewSettlement;

export async function action({ request }: { request: Request }) {
  try {
    const updateSettlementTransactionsRequest = await request.json();
    const offExchangeServiceAction = await OffExchangeService.settlementConfirmation(
      updateSettlementTransactionsRequest
    );
    return formatActionSuccessResponse(offExchangeServiceAction);
  } catch (error) {
    return formatActionErrorResponse(error);
  }
}
